@font-face {
    font-family: 'noto_sansbold';
    src: url('../fonts/notosans-bold-webfont.woff2') format('woff2'),
         url('../fonts/notosans-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'noto_sansregular';
    src: url('../fonts/notosans-regular-webfont.woff2') format('woff2'),
         url('../fonts/notosans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'noto_serifbold';
    src: url('../fonts/notoserif-bold-webfont.woff2') format('woff2'),
         url('../fonts/notoserif-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'noto_serifregular';
    src: url('../fonts/notoserif-regular-webfont.woff2') format('woff2'),
         url('../fonts/notoserif-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}