h1 {border-bottom:1px solid $medium-gray;padding-bottom:.5rem;margin-bottom:.75rem;}
h2 {border-bottom:1px solid $medium-gray;padding-bottom:.5rem;margin-bottom:.75rem;}
h3 {margin-bottom:.5rem;}

h2 {clear:both;}

blockquote {border-left:0;padding: 0 0 0 2.5rem;position:relative;}
blockquote p {color:$ci-first;font-style:italic;font-weight:bold;}

blockquote p:first-child:before {
	content: '"';
	color: $ci-first;
	position: absolute;
	font-size: 5rem;
	top: -1.5rem;
	left: -1rem;
}