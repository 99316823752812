// Background, Sticky Footer

html {
	height:100%;
}
body {
	height: 100%;
  width: 100%;
  display: table;
  table-layout: fixed;
  background: linear-gradient(to bottom, white, #FDF5DD) fixed;
}
@include breakpoint(small only) {
  body {
    font-size:0.9rem;
  }
}

header,
footer {
  display: table-row;
}

main {
  display: table-row;
  height: 100%;
}

main>.grid-container {
    padding-top:1rem;
    padding-bottom:1rem;
}
@include breakpoint(medium) {
  main>.grid-container {
    padding-top:3rem;
    padding-bottom:1rem;
  }
}

.logo {
  padding:1rem;
  font-family:$body-font-family;
  background:url("/assets/img/banner/banner.jpg") no-repeat;
  background-size:cover;
  background-position:50% 50%;
  span {
    font-family:$header-font-family;
    font-size:1rem;
    font-weight:700;
    padding-left:1rem;
  }
  @include breakpoint(medium) {
    span {
      font-size:1.5rem;
    }
  }
  @include breakpoint(large) {
    span {
      font-size:2.25rem;
    }
  }
  a {
    color:$black;
    text-decoration:none;
    display:inline-flex;
    justify-content:center;
    align-items:center;
    background:rgba(255,255,255,0.5);
    padding:.25rem .5rem;
  }
}
header {
  img {
    height:100px;
  }
}

@include breakpoint(medium) {
  header.animated .sticky.is-anchored {
    height:300px;
    transition: all .25s linear;
    .logo {
      padding:3rem 0;
    }
    img {
      height:140px;
      transition: all .25s linear;
    }
  }

  header.animated .sticky.is-stuck {
    transition: all .25s linear;
    .logo {
      padding:1rem 0;
    }
    img {
      height:100px;
      transition: all .25s linear;
    }
  }
}

.primary a,
.secondary a {
	color:$white;
}

.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

[data-sticky] {
  width:100%;
}

.sticky-container {
  z-index: 5;
}

// Fix for mobile

.is-at-bottom {
  top: 0 !important;
}

// Callout

.callout {
  box-shadow: 0 0 3px #777;
  @include breakpoint(small only) {
    margin: 0 0 0.75rem;
    padding:0.5rem 0.75rem;
  }
}

.callout.ci-first {
	border-top:8px solid $ci-first;
  border-right:1px solid $ci-first;
  border-left:1px solid $ci-first;
  border-bottom:1px solid $ci-first;
}
.callout.ci-second {
  border-top:8px solid $ci-second;
  border-right:1px solid $ci-second;
  border-left:1px solid $ci-second;
  border-bottom:1px solid $ci-second;
}
.callout.ci-third {
	border-top:8px solid $ci-third;
  border-right:1px solid $ci-third;
  border-left:1px solid $ci-third;
  border-bottom:1px solid $ci-third;
}

// Topbar

.top-bar {
  padding:0;
}
.dropdown.menu a {
    padding: 1.15rem;
    font-size:1.1rem;
}

.top-bar {
  .grid-container {
    padding:0;
    width:100%;
  }
}

@include breakpoint(large) {
  .top-bar {
    .grid-container {
      padding:inherit;
      width:inherit;
    }
  }
}

header {
  .callout {
    padding-top:0;
    padding-bottom:0;
    border-radius:0;
    margin-bottom:0;
  }
  .top-bar {
    a {
      color:$white;
      @include breakpoint(medium) {
        &:hover {
          background-color:lighten($ci-first, 10%);
          @include mui-fade(in);
        }
      }
    }
  }
  .is-active {
    background-color:lighten($ci-first, 10%);
  }
}

footer {
  .callout {
    padding-top:0;
    padding-bottom:0;
    border-radius:0;
    margin: 0 auto;
  }
  .menu {
    display:flex;
    justify-content:center;
    a {
      color:$white;
      @include breakpoint(medium) {
        &:hover {
          background-color:lighten($ci-third, 8%);
          @include mui-fade(in);
        }
      }
    }
  }
  @include breakpoint(medium) {
    .is-active {
      background-color:lighten($ci-third, 10%);
    }
  }
}

img.vorstand, img.round {
  border-radius:$global-radius;
  margin-bottom:0.5rem;
}

img.ausgabe {
  border-radius:$global-radius;
  border:1px solid $ci-second;
  margin-bottom:0.5rem;
}

img.img-right,
a.img-right {
  margin:0 auto 1rem;
  display:block;
}
img.img-right-sm,
a.img-right-sm {
  margin:0 0 1rem 0;
  display:block;
  position: relative;
  &:before {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    content: url(/assets/img/photo_library.svg);
    width: 56px;
    height: 56px;
    padding: 0.5rem;
    background: rgba(0,0,0,0.4);
    border-radius: 0.5rem;
  }
}
@include breakpoint(large) {
  img.img-right,
  a.img-right {
    float:right;
    margin:0 0 1rem 1rem;
  }
}
@include breakpoint(medium) {
  img.img-right-sm,
  a.img-right-sm {
    float:right;
    margin:0 0 1rem 1rem;
    &:before {
      left: inherit;
      right: 0.5rem;
    }
  }
}

// Accordion

.accordion-title {
  color: $black;
  text-decoration:none;
    &[aria-expanded="true"] {
    background-color: $ci-second;
    cursor:default;
  }
}

@include breakpoint(small only) {
.text-right {
    text-align:left;
  }
}
@include breakpoint(medium) {
  a[href^="tel"] {
    text-decoration:none;
    color:$black;
    cursor:text;
  }
}

// Pictures

.picture {
  &.full {
    display:flex;
    margin:0 auto;
  }
}

figure {
  background: #fdf5dd;
  padding:0.5rem;
  border-radius: 10px;
  margin: 0 0 1.25rem;
}
figcaption {
  padding-top: 0.5rem;
  font-size: 0.9rem;
}